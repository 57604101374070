<template>
  <div id="app">
    <div class="control">
      <div class="title">对比层</div>

      <div class="item">
        <span>透明度:</span>
        <el-slider
          v-model="opacity"
          style="width:150px;"
          :min="0"
          :step="0.01"
          :max="1"
        ></el-slider>
      </div>
      <div class="item"><span>当前:</span> {{ show }}</div>
      <!-- <div class="item"> <span>吻合:</span> 
            {{curCoincide ? '是' :'否'}}
            <el-switch
            v-model="curCoincide"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </div> -->
    </div>
    <div class="control right">
      <div class="title">遮罩层</div>
      <div class="item">
        <span>透明度:</span>
        <el-slider
          v-model="opacity"
          style="width:150px;"
          :min="0"
          :step="0.01"
          :max="1"
        ></el-slider>
      </div>
      <div class="item"><span>当前:</span> {{ maskShow }}</div>
    </div>
    <div
      class="control  "
      style="bottom:580px;max-height: 150px;overflow-y:scroll;"
      v-if="true"
    >
      <!-- <div class="title">对比</div> -->
      <div class="">
        <span>吻合:</span>
        {{ curCoincide ? "是" : "否" }}
        <el-switch
          v-model="curCoincide"
          @change="handleCurCoincideChange"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </div>
    </div>
    <div
      class="mask"
      :style="{
        opacity: opacity,
        background: curCoincide ? '#12CE66' : '#000000',
      }"
    >
      <el-carousel
        ref="swiperMask"
        indicator-position="none"
        height="400px"
        :autoplay="false"
        @change="handleSwiperChange2"
      >
        <el-carousel-item
          v-for="(item, index) in swiperItems"
          :key="`mask-floor-${index}`"
          class="swiper-item-container"
          :label="`${item.no}幢${item.floor}楼`"
        >
          <div class="swiper-item">
            <img :src="`https://surx.oss-cn-beijing.aliyuncs.com/hc/smart_location/overview/${item.id}.png`"/>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <el-carousel
      ref="swiper"
      indicator-position="none"
      height="400px"
      :autoplay="false"
      @change="handleSwiperChange"
    >
      <el-carousel-item
        v-for="(item, index) in swiperItems"
        :key="`mask-floor-${index}`"
        class="swiper-item-container"
        :label="`${item.no}幢${item.floor}楼`"
      >
        <div class="swiper-item">
          <img
            style="filter: invert(100%);"
            :src="`https://surx.oss-cn-beijing.aliyuncs.com/hc/smart_location/overview/${item.id}.png`"
          />
        </div>
      </el-carousel-item>
    </el-carousel>
    <div>
      <el-row>
        <el-col :span="8">
          <div class="table-container">
            <el-table :data="items" size="mini">
              <el-table-column prop="no" label="幢"></el-table-column>
              <el-table-column
                v-for="(floor, index) in 5"
                :key="index"
                :prop="`floor-${floor}`"
                :label="`${floor}层`"
              >
                <template slot-scope="scope">
                  <el-tooltip placement="top" effect="light">
                    <div slot="content">
                      <!-- <img style="height:178px;"  :src="`https://surx.oss-cn-beijing.aliyuncs.com/hc/smart_location/overview/${scope.row[`no-${item}`].id}`"/> -->
                      <div>
                        {{ scope.row[`floor-${floor}`].no }}幢{{ floor }}楼
                      </div>
                    </div>
                    <div
                      class="table-item"
                      @click="handleTableItemClick(scope.row[`floor-${floor}`])"
                      :class="{
                        active: scope.row[`floor-${floor}`].index === curSwiper,
                      }"
                      :style="
                        `background-image:url(${`https://surx.oss-cn-beijing.aliyuncs.com/hc/smart_location/overview/${
                          scope.row[`floor-${floor}`].id
                        }.png`})`
                      "
                    >
                      {{ renderGroups(scope.row[`floor-${floor}`]) }}
                    </div>
                    <!-- <img class="table-item-img"    @click="handleTableItemClick(scope.row[`no-${item}`])" :src=""/> -->
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="table-container">
            <el-table :data="items" size="mini">
              <el-table-column prop="no" label="幢"></el-table-column>
              <el-table-column
                v-for="(floor, index) in 5"
                :key="index"
                :prop="`floor-${floor}`"
                :label="`${floor}层`"
              >
                <template slot-scope="scope">
                  <el-tooltip placement="top" effect="light">
                    <div slot="content">
                      <!-- <img style="height:178px;"  :src="`https://surx.oss-cn-beijing.aliyuncs.com/hc/smart_location/overview/${scope.row[`no-${item}`].id}`"/> -->
                      <div>
                        {{ scope.row[`floor-${floor}`].no }}幢{{ floor }}楼
                      </div>
                    </div>
                    <div
                      class="table-item"
                      @click="
                        handleTableItemClick2(scope.row[`floor-${floor}`])
                      "
                      :class="{
                        active:
                        scope.row[`floor-${floor}`].index === curSwiper2,
                        target: scope.row[`floor-${floor}`].index === curSwiper,
                      }"
                      :style="
                        `background-image:url(${`https://surx.oss-cn-beijing.aliyuncs.com/hc/smart_location/overview/${
                          scope.row[`floor-${floor}`].id
                        }.png`})`
                      "
                    >
                      {{ renderGroups(scope.row[`floor-${floor}`]) }}
                    </div>
                    <!-- <img class="table-item-img"    @click="handleTableItemClick(scope.row[`no-${item}`])" :src=""/> -->
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="table-container">
            <el-collapse v-model="activeNames">
              <el-collapse-item
                v-for="(group, key) in showGroups"
                :key="key"
                :title="`房型:${key}`"
                :name="key"
              >
                <template slot="title">
                  <div
                    style="display:flex;justify-content:space-between;width:100%;"
                  >
                    <div>房型{{ key }}</div>
                    <div style="">
                      <el-button
                        type="primary"
                        round
                        size="mini"
                        @click.stop="handleShowRoomEditDialog(key)"
                        >编辑房型</el-button
                      >
                    </div>
                  </div>
                </template>
                <div
                  style="display: flex;width:100%;display:flex;flex-wrap:wrap;"
                >
                  <el-button
                    size="small"
                    v-for="(item, index) in group"
                    :key="index"
                  >
                    {{ item | id2NameFilter }}</el-button
                  >
                </div>
              </el-collapse-item>
            </el-collapse>
            <!-- <div v-for="(group,key) in showGroups" :key="key">
                        <div>类型{{key}}</div>
                        <div style="display: flex;" >
                            <span v-for="(item,index) in group" :key="index">  {{item}},</span>
                        </div>
                    </div> -->
          </div>
        </el-col>
      </el-row>
    </div>
    <el-dialog
      fullscreen
      :visible.sync="editRoomDialogVisiable"
      :title="`房型编辑-${currentRoomType}(${currentGroupItems.length})`"
    >
      <!-- <img src="https://surx.oss-cn-beijing.aliyuncs.com/hc/smart_location/overview/11.png"/> -->
      <el-row :grid="5">
        <el-col :span="8">
          <div style="display:flex;justify-content:center;">
            <div class="room-layer">
              <el-carousel
                ref="layerSwiper"
                indicator-position="none"
                @change="handleLayerSwiperChange"
                style="width: 100%;height: 100%;"
                :autoplay="false"
              >
                <el-carousel-item
                  v-for="(item, index) in currentDetaileInfo.category[subCategoryTabsActiveName].rooms"
                  :key="`mask-floor-${index}`"
                  class="swiper-item-container"
                  :label="`${item[0]}幢${item[1]}楼`"
                >
                  <!-- <div class="swiper-item">
                                    <image style="filter: invert(100%);"  :src="`https://surx.oss-cn-beijing.aliyuncs.com/hc/smart_location/overview/${item.id}.png`"></image>
                                </div> -->
                  <img class="room-layer" :src="`https://surx.oss-cn-beijing.aliyuncs.com/hc/smart_location/overview/${item}.png`" />
                </el-carousel-item>
              </el-carousel>
              <div
                style="    z-index: 99;
                        display: block;
                        position: absolute;
                        top: 0;
                    "
              >
                <div
                  v-for="(room, index) in roomsTableForm"
                  :style="formatStyle(room, index)"
                  class="room"
                  :key="index"
                ></div>
              </div>
            </div>
          </div>
        
        </el-col>
        <el-col :span="16">
          <el-form inline>
            <el-row type="flex" align="center" :gutter="10">
              <el-col :span="8">
                <el-input
                  size="small"
                  v-model="fastAddRoomNumbers"
                  placeholder="输入房间数量快速新增"
                ></el-input>
              </el-col>
              <el-col :span="4">
                <el-button size="mini" type="primary" @click="handleAddRooms"
                  >新增</el-button
                >
              </el-col>

              <el-col :span="8">
                <el-form-item label="缩放">
                  <!-- <el-input size="small" v-model="fastAddRoomNumbers" placeholder="输入房间数量快速新增"></el-input> -->
                  <el-slider
                    v-model="zoom"
                    :step="0.25"
                    show-stops
                    :min="0.25"
                    :max="2"
                    style="width:150px;"
                  ></el-slider>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div>
            <el-button
              size="mini"
              type="primary"
              round
              @click="handleAddSubCategory"
              >ADD SUB CATEGORY
            </el-button>
            <el-tabs v-model="subCategoryTabsActiveName">
              <el-tab-pane
                v-for="(cate, index) in roomTypeDetaileInfo[currentRoomType].category"
                :label="`${currentRoomType}-${index}`"
                :name="`${index}`"
                :key="index"
              >
                <div style="padding:10px;font-size:18px;color:black;font-weight:bold;">OVERVIEW:</div>
                <Uploader v-model="cate.overview"  @change="saveDetaile"/>
                <div  style="padding:10px;font-size:18px;color:black;font-weight:bold;">ROOMS:</div>
                <el-table :data="roomsTableForm" width="100%">
                  <el-table-column label="ROOM" prop="no" width="70">
                  </el-table-column>
                  <el-table-column label="WIDTH" prop="width">
                    <template slot-scope="scope">
                      <el-input-number
                        v-model.number="scope.row.width"
                        :controls="false"
                        size="mini"
                        :precision="2"
                        :step="0.1"
                        style="width:90px;"
                        placeholder="width"
                      />
                    </template>
                  </el-table-column>
                  <el-table-column label="HEIGHT" prop="height">
                    <template slot-scope="scope">
                      <el-input-number
                        v-model.number="scope.row.height"
                        :controls="false"
                        size="mini"
                        :precision="2"
                        :step="0.1"
                        style="width:90px;"
                        placeholder="height"
                      />
                    </template>
                  </el-table-column>
                  <el-table-column label="TOP" prop="top">
                    <template slot-scope="scope">
                      <el-input-number
                        v-model.number="scope.row.top"
                        :controls="false"
                        size="mini"
                        :precision="2"
                        :step="0.1"
                        style="width:90px;"
                        placeholder="top"
                      />
                    </template>
                  </el-table-column>
                  <el-table-column label="LEFT" prop="left">
                    <template slot-scope="scope">
                      <el-input-number
                        v-model.number="scope.row.left"
                        :controls="false"
                        size="mini"
                        :precision="2"
                        :step="0.1"
                        style="width:90px;"
                        placeholder="left"
                      />
                    </template>
                  </el-table-column>

                  <el-table-column label="ZINDEX" prop="zindex">
                    <template slot-scope="scope">
                      <el-input
                        size="mini"
                        v-model="scope.row.zindex"
                        style="width:50px;"
                        placeholder="zindex"
                      ></el-input>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column label="OVERVIEW" prop="zindex">
                    <template slot-scope="scope">
                        <Uploader v-model="roomTypeDetaileInfo[currentRoomType].category[index].overview" :key="scope.$index"  >
                        </Uploader>
                    </template>
                  </el-table-column> -->
                  <el-table-column label="NORMAL" prop="zindex">
                    <template slot-scope="scope">
                      <uploader v-model="roomTypeDetaileInfo[currentRoomType].category[index].layers[scope.$index].normal" @change="saveDetaile"/>
                      <!-- <img :src="scope.row.normal"  style="width:70px" /> -->
                    </template>
                  </el-table-column>
                  <el-table-column label="SELECTED" prop="zindex">
                    <template slot-scope="scope">
                      <uploader v-model="roomTypeDetaileInfo[currentRoomType].category[index].layers[scope.$index].selected" @change="saveDetaile"/>
                    </template>
                  </el-table-column>
                  <el-table-column label="DISABLED" prop="zindex">
                    <template slot-scope="scope">
                        <uploader v-model="roomTypeDetaileInfo[currentRoomType].category[index].layers[scope.$index].disabled" @change="saveDetaile"/>
                    </template>
                  </el-table-column>
                  <el-table-column label="OPERA" width="150" prop="op">
                    <template slot-scope="scope">
                      <el-button
                        size="mini"
                        type="primary"
                        round
                        @click="handleRemove(scope.$index)" >IMG</el-button>
                      <el-button
                        size="mini"
                        type="danger"
                        round
                        @click="handleRemove(scope.$index)" >DEL </el-button>
                      <!-- <el-input size="mini" v-model="scope.row.zindex" style="width:50px;"  placeholder="zindex"></el-input> -->
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-col>
      </el-row>
        <div class="edit-swiper-list">
            <div
              @click="handleToChangeLayerSwiperChange(index)"
              class="item"
              v-for="(item, index) in currentDetaileInfo.category[subCategoryTabsActiveName].rooms"
              :key="index"
              :class="{ active: curLayerSwiper === index }"
            >
                <div class="cover" @click="handleChangeCategory(item)">
                    <img :src="`https://surx.oss-cn-beijing.aliyuncs.com/hc/smart_location/overview/${item}.png`" />
                    <div class="category" :style={color:colors[findCategory(item)]}>{{currentRoomType}}-{{findCategory(item)}}</div>
                </div>
                <div class="name">{{ item[0] }}幢{{ item[1] }}楼</div>
            </div>
            <div>
              <el-select multiple v-model="currentDetaileInfo.category[subCategoryTabsActiveName].rooms">
                <el-option v-for="item in currentGroupItems" 
                :disabled="!!currentDetaileInfo.category.find(c=>c.rooms.find(r=>r=== item))"
                 :key="item" :label="`${item[0]}幢${item[1]}层`" :value="item"></el-option>
              </el-select>
            </div>
        
        </div>
      <div slot="footer">
        <el-button type="primary" @click="handlePrevType">Prev</el-button>
        <el-button type="primary" @click="handleNextType">Next</el-button>
      </div>
    </el-dialog>
    <el-dialog
      width="40%"
      append-to-body
      :visible.sync="typeImageManagerDialogVisiable"
      :title="`CROP MANAGER`"
    >
      <el-form>
        <el-form-item label="OVERVIEW LAYER:">
          <el-upload
            class="avatar-uploader"
            action="https://ojkcyzn.wenzhou.gov.cn:8086/server/fileUpload/upload"
            :show-file-list="false"
            :headers="uploadFileHeaders"
            name="attachFiles"
            :on-preview="handlePreviewImage"
            :on-success="handleAvatarSuccess"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="showImagePreviewDialog">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
const setStorage = (key, val) => localStorage.setItem(key, JSON.stringify(val));
const getStorage = (key) =>
  localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : null;
  import Uploader from "./components/uploader.vue"
export default {
  components: {Uploader},
  filters: {
    id2NameFilter(val) {
      // console.log("格式化id",val)
      let arr = `${val}`.split("");
      return `${arr[0]}幢${arr[1]}楼`;
    },
  },
  computed: {
    currentDetaileInfo() {
      return this.roomTypeDetaileInfo[this.currentRoomType];
    },
    nextType() {
      let index = Object.keys(this.showGroups).findIndex(
        (key) => key === this.currentRoomType
      );
      if (this.groups[index + 1]) {
        return String.fromCharCode(65 + index + 1);
      } else {
        return null;
      }
    },
    prevType() {
      let index = Object.keys(this.showGroups).findIndex(
        (key) => key === this.currentRoomType
      );
      if (this.groups[index - 1]) {
        return String.fromCharCode(65 + index - 1);
      } else {
        return null;
      }
    },
    currentRoom() {
      let item = this.swiperItems.find(
        (item) => item.id === this.currentRoomId
      );
      if (item) {
        item.name = `${item.no}幢${item.floor}层`;
        item.rooms = {
          img: "",
          no: 1,

          left: 0,
          top: 0,
          zindex: 0,
        };
      }
      return item || {};
    },
    //当前编辑房型的 底图
    currentTypeLayerImage() {
      return `https://surx.oss-cn-beijing.aliyuncs.com/hc/smart_location/overview/${this.showGroups[this.currentRoomType][0]}.png`;
    },
    //当前房型下的列表
    currentGroupItems() {
      return this.showGroups[this.currentRoomType];
    },
    showGroups() {
      return this.groups.reduce((obj, group, index) => {
        obj[String.fromCharCode(65 + index)] = group;
        return obj;
      }, {});
    },
    curCoincides() {
      console.log("curCoincides计算");
      return Object.keys(this.coincideMap).filter(
        (key) => this.coincideMap[key]
      );
    },
    coincideKey() {
      return `${this.curId}-${this.curMaskId}`;
    },
    curId() {
      return this.swiperItems[this.curSwiper].id;
    },
    curMaskId() {
      return this.swiperItems[this.curSwiper2].id;
    },
    swiperItems() {
      return this.no
        .map((no) => {
          return new Array(5).fill().map((item, floor) => {
            return {
              no,
              floor: floor + 1,
              id: `${no}${floor + 1}`,
            };
          });
        })
        .flat();
    },
    items() {
      let swiperIndex = 0;
      return this.no.map((no, index) => {
        return new Array(5).fill().reduce((column, item, floor) => {
          column["no"] = `${no}幢`;
          column[`floor-${floor + 1}`] = {
            id: `${no}${floor + 1}`,
            no,
            floor: floor + 1,
            index: swiperIndex,
          };
          swiperIndex++;
          return column;
        }, {});
      });
    },
  },
  watch: {
    showGroups(nv) {
      this.activeNames = Object.keys(nv);
    },
    roomsTableForm: {
      handler(nv, ov) {
        console.log("房型编辑Change", {
          currentRoomType: this.currentRoomType,
          roomsTableForm: this.roomsTableForm,
          roomTypeDetaileInfo: this.roomTypeDetaileInfo,
        });
        if (!this.roomTypeDetaileInfo[this.currentRoomType]) {
          this.roomTypeDetaileInfo[this.currentRoomType] = {
            rooms: [],
            category: [],
            desc: "",
          };
        }
        this.roomTypeDetaileInfo[this.currentRoomType][
          "rooms"
        ] = this.roomsTableForm;
         this.saveDetaile()
      },
      deep: true,
    },
    coincideMap: {
      deep: true,
      handle(nv) {},
    },
    groups: {
      deep: true,
      handler(nv) {
        console.log("组更新", nv);
        setStorage("GROUPS", nv);
      },
    },
    curCoincide(nv) {
      // this.coincideMap[this.coincideKey] = nv
      this.handleCurCoincideChange(nv);
    },
    curSwiper2(nv) {
      this.$refs.swiperMask.setActiveItem(nv);
      let item = this.swiperItems[nv];
      this.maskShow = `${item.no}幢${item.floor}层`;

      this.curCoincide =
        this.findGroupsIndex(this.curId, item.id) !== undefined;
      console.log(
        "遮罩层变化",
        nv,
        this.curId,
        this.findGroupsIndex(this.curId, item.id) !== undefined
      );
    },

    curSwiper(nv) {
      this.$refs.swiper.setActiveItem(nv);
      let item = this.swiperItems[nv];
      this.show = `${item.no}幢${item.floor}层`;
      this.curCoincide =
        this.findGroupsIndex(item.id, this.curMaskId) !== undefined;

      let group = this.findGroupsIndex(item.id);
      if (group) {
        // console.log("找到的组索引",group)
        // this.groups[group].push(this.curMaskId)
      } else {
        this.groups.push([item.id]);
      }
      this.curSwiper2 = this.findNextIndex();
    },
  },
  mounted() {
    window.Tools = this;
    document.onkeydown = (e) => {
      var key = window.event.keyCode;
      console.log("keydown", key);
      if ([38, 39, 40, 37, 32, 65, 87, 68, 83, 67].includes(key)) {
        this.handleKeydown(key);
      }
    };
    for (let item of this.swiperItems) {
      for (let item2 of this.swiperItems) {
        this.coincideMap[`${item.id}-${item2.id}`] = false;
      }
    }
    this.$nextTick(() => {
      this.$refs.swiper.setActiveItem(this.curSwiper);
      this.$refs.swiperMask.setActiveItem(this.curSwiper2);
    });
    this.roomsTableForm = this.roomTypeDetaileInfo[this.currentRoomType]
      ? this.roomTypeDetaileInfo[this.currentRoomType]["rooms"]
      : [];
  },
  data: function() {
    return {
        dialogImageUrl:"",
      colors: ["red", "orange", "yellow", "green", "cyan", "blue", "purple"],
      subCategoryTabsActiveName: 0,
      uploadFileHeaders: {
        Authorization:
          "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6eyJ0b2tlbiI6IjUzMGQwMjM3LTA5MmItNDYwZC05NDAyLTFhMGRjNjVhMzM0MSIsImNvZGUiOiI5MTMzMDMwMU1BMkFXVDhMNkwiLCJ1c2VyVHlwZSI6IjIiLCJ1c2VySWQiOiIxIiwibG9naW5UaW1lIjoxNjI2OTQ2NDExMjE2LCJleHBpcmVUaW1lIjoxNjI2OTUwMDExMjE2LCJpcGFkZHIiOiIxMjIuMjI0LjEzMS4xNzgiLCJsb2dpbkxvY2F0aW9uIjoi5rWZ5rGf55yBIOadreW3nuW4giIsImJyb3dzZXIiOiJDaHJvbWUgOSIsIm9zIjoiTWFjIE9TIFgiLCJ1c2VyIjpudWxsLCJhZG1pblZvIjp7ImlkIjoxLCJ1c2VyTmFtZSI6InpzIiwiY29tcGFueU5hbWUiOiLmtZnmsZ_pk4LojrHmm7zliqjlipvnp5HmioDmnInpmZDlhazlj7giLCJjb2RlIjoiOTEzMzAzMDFNQTJBV1Q4TDZMIiwibmFtZSI6IuW8oOS4iSIsInBhc3N3b3JkIjpudWxsLCJzYWx0IjpudWxsLCJsYXN0TG9naW5UaW1lIjpudWxsLCJsb2dpbklwIjoiMTIyLjIyNC4xMzEuMTc4IiwiaXNWYWxpZCI6IjEiLCJoZWFkSW1nVXJsIjpudWxsLCJ0eXBlIjoiMiIsImNyZWF0ZVRpbWUiOm51bGwsIm1vZGlmeVRpbWUiOm51bGwsImNyZWF0ZVVzZXIiOiIiLCJtb2RpZnlVc2VyIjoiIiwidG9rZW4iOm51bGx9LCJhZG1pblBlcnNvbmFsVXNlckVudGl0eSI6bnVsbH19.CGmEcdFL1-NTavIRqvvg1BxbDCz8ton_J6bfdSmo1UkBu1UrMbbdhEOrMsRrCkrsJWoCS0xXiZ7MJrwCf8ZQ3A",
      },
      showImagePreviewDialog: false,
      imageUrl: "",
      zoom: 0.5,
      curLayerSwiper: 0,
      roomsTableForm: [
        //房间列表编辑表单
        // {
        //     no:1,
        //     width:20,
        //     height:10,
        //     top:0,
        //     left:0,
        //     zindex:0,
        // }
      ],
      roomTypeDetaileInfo: getStorage("ROOM_TYPE_DETAILES") || {},
      //快速添加房间
      fastAddRoomNumbers: 1,
      editRoomDialogVisiable: false, //房型编辑
      typeImageManagerDialogVisiable: false, //切图编辑
      currentRoomId: "11",
      currentRoomType: "A",
      activeNames: ["A"],
      coincideMap: {}, //吻合表
      curCoincide: false,
      curSwiper: 0,
      curSwiper2: 1,
      no: [1, 2, 3, 5, 6, 7, 8],
      opacity: 0.5,
      maskShow: "1幢1层",
      show: "1幢1层",
      groups: getStorage("GROUPS") || [["11"]],
    };
  },
  methods: {
    //从当前分类中移除子类
    handleDeleteRoomFromCategory(index){
      console.log(`从`,this.currentDetaileInfo.category[this.subCategoryTabsActiveName].rooms,`中移除第${index}个元素`)

    },
      //更新子类
      handleChangeCategory(id){
          return;
        //   let oldCateIndex = this.findCategory(id)
        //   if(oldCateIndex !== -1){
        //     let oldRoomByCateIndex = this.currentDetaileInfo.category[oldCateIndex].rooms.findIndex(item=>item===id)
        //     this.currentDetaileInfo.category[oldCateIndex].rooms.splice(oldRoomByCateIndex,1)  
        //   }
            
      
        //   this.currentDetaileInfo.category[this.subCategoryTabsActiveName].rooms.push(id)
        // //   console.log("更新子类",{type:this.currentRoomType,currentDetaileInfo:this.currentDetaileInfo,id,oldCateIndex,oldRoomByCateIndex})
        // this.saveDetaile();
          


      },
    saveDetaile(){
          setStorage("ROOM_TYPE_DETAILES", this.roomTypeDetaileInfo);
    },
    findCategory(id){
        // console.log("寻找子类索引",this.currentDetaileInfo)
        return this.currentDetaileInfo.category.findIndex(item=>(item.rooms || []).find(r=>r===id))
         
    },
    handleAddSubCategory() {
      this.roomTypeDetaileInfo[this.currentRoomType].category.push({
        overview: "",
        normal: "",
        selected: "",
        disabled: "",
        rooms:[],
      });
      this.saveDetaile()
    },
    handlePreviewImage(e) {
      console.log("文件预览", e);
    },
    handleAvatarSuccess(e) {
      console.log("文件上传成功", e);
      let result = e.result;
      this.imageUrl = `${result.showUrlPrefix}${
        result.realPaths.split(",")[0]
      }`;
    },
    handlePrevType() {
      if (!this.prevType) {
        this.$message.error("没了");
        return;
      }
      this.handleShowRoomEditDialog(this.prevType);
    },
    handleNextType() {
      // let nextType =
      // console.log(`当前${this.currentRoomType},位置${index}`)
      if (!this.nextType) {
        this.$message.error("没了");
        return;
      }
      this.handleShowRoomEditDialog(this.nextType);
    },
    handleToChangeLayerSwiperChange(index) {
      this.$refs.layerSwiper.setActiveItem(index);
    },
    handleLayerSwiperChange(cur) {
      this.curLayerSwiper = cur;
    },
    //格式化样式
    formatStyle(room, index) {
      const layer = this.roomTypeDetaileInfo[this.currentRoomType]["category"][this.subCategoryTabsActiveName].layers[index]
      console.log("格式化样式",index,layer, room);
      let raw = Object.keys(room).reduce(
        (o, k) => ({
          ...o,
          [`${k}`]: room[k] * this.zoom,
        }),
        {}
      );
      let style = {
        width: `${raw.width || 0}px`,
        height: `${raw.height || 0}px`,
        top: `${30 + +(raw.top || 0)}px`,
        left: `${25.25 + +(raw.left || 0)}px`,
        zIndex: room.zindex,
        // backgroundColor: this.colors[index],
        backgroundImage:`url(${layer.normal})`,
        backgroundSize:'100% 100%'
      };
      return style;
    },
    //添加房间
    handleAddRooms() {
      let newRooms = new Array(+this.fastAddRoomNumbers)
        .fill()
        .map((item, index) => ({
          no: this.roomsTableForm.length + (index + 1),
          width: 10,
          height: 10,
          top: 31,
          left: 33,
          zindex: 0,
        }));
      console.log(
        "添加房间",
        `新增房间数[${this.fastAddRoomNumbers}]`,
        `房间生成new Array(${this.fastAddRoomNumbers}).fill(1)=`,
        new Array(+this.fastAddRoomNumbers).fill(1)
      );
      this.roomsTableForm.push(...newRooms);
    },
    //移除房间
    async handleRemove(index) {
      let confirm = await this.$confirm(
        "不可逆操作 将丢失数据",
        "DELETE ROOM",
        { type: "warning" }
      );
      console.log("确认删除", confirm);
      this.roomsTableForm.splice(index, 1);
    },
    //显示类型编辑弹窗
    handleShowRoomEditDialog(key) {
      this.currentRoomType = key;
      // this.currentRoomId =id
      if (!this.roomTypeDetaileInfo[this.currentRoomType]) {
        this.roomTypeDetaileInfo[this.currentRoomType] = {
          rooms: [],
          category: [
            {
              overview: "",
              normal: "",
              selected: "",
              disabled: "",
              rooms:[]
            },
          ],
          desc: "",
        };
      }
      // 如果子类为空，添加默认子类
      if (
        !this.roomTypeDetaileInfo[this.currentRoomType]["category"] ||
        !this.roomTypeDetaileInfo[this.currentRoomType]["category"].length
      ) {
        this.roomTypeDetaileInfo[this.currentRoomType]["category"] = [
          {
            layers:this.currentDetaileInfo.rooms.map(_item=>({
                normal: "",
                selected: "",
                disabled: "",
            })),
            rooms: this.currentDetaileInfo.rooms,
          },
        ];
      }
      this.roomTypeDetaileInfo[this.currentRoomType]["category"] = this.roomTypeDetaileInfo[this.currentRoomType]["category"].map((item,index)=>{
          if(!item.rooms){
              item.rooms = !index ? this.currentGroupItems :[]
          }
          if(!item.layers){
            item.layers = this.currentDetaileInfo.rooms.map(item=>({
                normal: "",
                selected: "",
                disabled: "",
            }))
          }
          if(item.overview === undefined){
            item.overview = ""
          }
          return item;
      })
      this.saveDetaile()

      this.roomsTableForm =
        this.roomTypeDetaileInfo[this.currentRoomType]["rooms"] || [];
      this.editRoomDialogVisiable = true;
      this.curLayerSwiper = 0;
    },
    findNextIndex() {
      let index = this.curSwiper ? 1 : 0;
      for (let i = this.curSwiper; i < this.swiperItems.length; i++) {
        let item = this.swiperItems[i];
        if (!item.groups && item.id !== this.curId) {
          index = i;
          break;
        }
      }
      return index;
    },
    findGroupsIndex(id, id2) {
      let index;
      for (let i in this.groups) {
        if (
          this.groups[i].includes(id) &&
          (id2 ? this.groups[i].includes(id2) : true)
        ) {
          index = i;
          break;
        }
      }
      return index;
    },
    cleanFromGroupsById(id) {
      let groupIndex = this.findGroupsIndex(id);
      if (groupIndex !== undefined) {
        let index = this.groups[groupIndex].findIndex((item) => item === id);
        console.log(`从组中删除`, {
          id,
          groupIndex,
          index,
        });
        this.groups[groupIndex].splice(index, 1);
        if (!this.groups[groupIndex].length) {
          this.groups.splice(groupIndex, 2);
        }
      }
    },
    renderGroups(item) {
      // console.log("组查找",item)
      let index = this.findGroupsIndex(item.id);
      if (index) {
        this.swiperItems[item.index].groups = String.fromCharCode(65 + +index);
      }
      return String.fromCharCode(65 + +index);
    },
    createdGrop(id, maskid, state) {
      let index;
      for (let i in this.groups) {
        if (this.groups[i].includes(id)) {
          index = i;
          break;
        }
      }
    },
    handleKeydown(key) {
      let keyActions = {
        //SPACE
        32: () => {
          this.curCoincide = !this.curCoincide;
        },
        //LEFT
        37: () => {
          if (this.curSwiper2 - 1 < 0) return;
          if (this.swiperItems[this.curSwiper2 - 1].id === this.curId) {
            this.curSwiper2 -= 2;
          } else {
            this.curSwiper2 -= 1;
          }
        },
        //UP
        38: () => {
          this.curSwiper2 -= 5;
        },
        //RIGHT
        39: () => {
          if (this.swiperItems[this.curSwiper2 + 1].id === this.curId) {
            this.curSwiper2 += 2;
          } else {
            this.curSwiper2 += 1;
          }
          // this.curSwiper2+=1
        },
        //DOWN
        40: () => {
          this.curSwiper2 += 5;
        },
        //A
        65: () => {
          this.curSwiper -= 1;
        },
        //W
        87: () => {
          this.curSwiper -= 5;
        },
        //D
        68: () => {
          this.curSwiper += 1;
        },
        //S
        83: () => {
          this.curSwiper += 5;
        },
        //C
        67: () => {
          this.cleanFromGroupsById(this.curMaskId);
        },
      };
      keyActions[key]();
    },
    handleCurCoincideChange(val) {
      console.log(
        "当前吻合状态Change",
        val,
        "当前项",
        this.curId,
        "当前遮罩",
        this.curMaskId
      );
      let group = this.findGroupsIndex(this.curId);
      let inTheGroup = this.findGroupsIndex(this.curId, this.curMaskId);
      if (this.curId === this.curMaskId) {
        return;
      }
      if (val) {
        if (group) {
          console.log("找到的组索引", group);
          if (inTheGroup === undefined) {
            this.groups[group].push(this.curMaskId);
          }
        } else {
          this.groups.push([this.curId, this.curMaskId]);
        }
      } else {
        console.log(
          `${this.curId},${this.curMaskId}是否在一个组内`,
          inTheGroup
        );
        if (inTheGroup !== undefined) {
          let index = this.groups[inTheGroup].findIndex(
            (item) => item === this.curMaskId
          );
          console.log(`在一个组内 移除`, index);
          this.groups[inTheGroup].splice(index, 1); //移除
        }
      }
    },
    handleSwiperChange(index) {
      console.log("当前显示", index);
      this.curSwiper = index;
    },
    handleSwiperChange2(index) {
      console.log("当前显示", index);
      this.curSwiper2 = index;
    },
    handleTableItemClick(item) {
      console.log("当前点击表格项目1", item);
      this.$refs.swiper.setActiveItem(item.index);
      this.show = `${item.no}幢${item.floor}层`;
    },
    handleTableItemClick2(item) {
      console.log("当前点击表格项目2", item);
      this.curSwiper2 = item.index;
    },
  },
};
</script>

<style>
body {
  padding: 0px;
  margin: 0px;
  background-color: #f3f3f3;
}

#app {
  width: 100%;
}
.close {
    position: absolute;
    right: 0;
    top: -0px;
}
.swiper-item-container {
  /* height:756px; */
  display: flex;
  justify-content: center;
}
.swiper-item-container .room-layer{
  opacity: .35
}
.swiper-item {
  /* width: 548px ; */
  height: 400px;
  /* background-color:red; */
  display: flex;
  justify-content: center;
  flex: 1;
}

.swiper-item img {
  /* width:100%; */
  height: 100%;
}

.table-item {
  height: 34px;
  width: 47px;
  background-size: cover;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  font-weight: bold;
  font-size: 16px;
}

.table-item.active {
  border: 2px solid #f00;
}

.table-item.target {
  border: 2px dashed red;
}

.mask {
  background: black;
  /* height: 500px; */
  width: 100%;
  position: absolute;
  z-index: 10;
}

.control {
  position: absolute;
  color: #666;
  z-index: 99;
  padding: 15px;
  width: 300px;
  background: white;
  border-radius: 5px;
  box-shadow: 5px 5px 1px 1px #0000001f;
}

.control.right {
  right: 0;
}

.control.bottom {
  bottom: 300px;
}

.control.center {
  /* right:0px; */
  left: calc(50% - 150px);
}

.control .title {
  padding: 5px;
  font-weight: bold;
  font-size: 18px;
  color: #333;
}

.control .item {
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
}

.control .item span {
  color: #555;
  font-weight: bold;
  margin-right: 5px;
}

.table-container {
  /* padding: 10px; */
  max-height: 364px;
  overflow-y: scroll;
  box-shadow: 4px 5px 3px #00000038;
  margin: 10px;
  border-radius: 10px;
  overflow: hidden;
  background: white;
  overflow-y: scroll;
  padding: 10px;
}

.room-layer .room {
  width: 10px;
  height: 10px;
  position: absolute;
  /* background: rgba(255, 0, 0, 0.42); */
  opacity: 1;
  cursor: pointer;
  box-sizing: border-box;
  overflow: hidden;
}

.room-layer .room:hover {
  opacity: 0.8;
  /* border: 1px solid currentColor; */
}

.room-layer {
  width: 385px;
  height: 275px;
  background-color: #00000022;
  /* background-image: url("https://surx.oss-cn-beijing.aliyuncs.com/hc/smart_location/overview/11.png"); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  box-sizing: border-box;
  /* padding:22.36px; */
}

.edit-swiper-list {
  display: flex;
  justify-content: flex-start;
  /* max-width: 500px; */
  overflow-x: scroll;
  position: relative;

}
.edit-swiper-list .item .cover{
    width: 96.25px;
    height: 68.75px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.edit-swiper-list .item.active .cover .category{
    border:2px solid currentColor;
}

.edit-swiper-list .item .cover .category {
    position:absolute;
    width:100%;
    height: 100%;
    top: 0;
    left:0;
    font-size:24px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color:red;
    border:2px dashed currentColor;
 
    /* background:red; */
    /* opacity: 0.5; */
}

.edit-swiper-list .item {
  width: 96.25px;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;

  margin-right: 15px;
}

.edit-swiper-list .item .cover img:hover {
  /* border: 1px solid blue; */
  box-shadow: 4px 4px 2px 0px #0000002e;
}

.edit-swiper-list .item.active .cover img {
  box-shadow: 4px 4px 2px 0px #0000002e;
}

.edit-swiper-list .item .cover img {
  width: 96.25px;
  height: 68.75px;
  border-radius: 4px;
}

.edit-swiper-list .item .name {
  text-align: center;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
